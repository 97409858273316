import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';

import { useApp } from 'contexts/AppContext';
import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import {
  ACTIVATION,
  CONNEXION,
  DATA_PROVIDERS,
  DOMAINCONFIG,
  IMPORTMODEL,
  USERS
} from 'utils/rest';

import { Text } from 'components/DataDisplay';
import Image from 'components/Image';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm, ModalSetup } from './components';

function DomainContainer() {
  const { domainConfig = [], resfreshDomainConfig } = useApp();
  const [rows, setRows] = useState([]);
  const [domainConfigList, setDomainConfigList] = useState([domainConfig]);
  const [dataProviders, setDataProviders] = useState([]);

  const [presetList, setPresetList] = useState([]);
  const [adminRole, setAdminRole] = useState();
  const [openModal, setModal] = useState(null);
  const [connectorsConnexions, setConnectorsConnexions] = useState([]);
  const [importModels, setImportModels] = useState([]);
  const [activableMedias, setActivableMedias] = useState([]);

  const [loaded, setLoaded] = useState(false);

  const { adminRights, user } = useUser();
  const { themeColors } = useTheme();

  const closeModal = () => {
    setModal(null);
  };

  const normalizeDataProviders = providers => {
    return providers.map(provider => ({
      key: provider,
      label: provider
    }));
  };

  const getDataProviders = async () => {
    const response = await DATA_PROVIDERS.get();
    return normalizeDataProviders(response);
  };

  const getPresetList = async () => {
    try {
      const result = await ACTIVATION.getActivationPresets();
      return result;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };

  const getBoolValue = value => {
    return value ? 'Oui' : 'Non';
  };

  const getDatas = async () => {
    const fetchedProviders = await getDataProviders();
    setDataProviders(fetchedProviders);
    const presets = await getPresetList();
    const allActivableMedias = await ACTIVATION.getActivableMedias();
    setActivableMedias(allActivableMedias);
    setPresetList(presets);
    const roles = await USERS.getRoles();
    setAdminRole(roles.find(el => el.name === 'ADMIN'));
    const data = { ...domainConfig };
    const connexions = await CONNEXION.getConnexions();
    const resImportModels = await IMPORTMODEL.get();
    if (data) {
      data.dataProviders = normalizeDataProviders(data?.dataProviders ?? []);
      data.excludeDataProviders = normalizeDataProviders(
        data?.excludeDataProviders ?? []
      );
      setDomainConfigList([data]);
      const mapped = {
        logo: data.logo ? (
          <Image src={data.logo} height="37px" alt={`${data.domain} logo`} />
        ) : (
          undefined
        ),
        domain: data.domain,
        features: data.features,
        name: data.name,
        id: data.id,
        _id: data._id,
        crm: getBoolValue(data?.modules?.crm),
        audiences: getBoolValue(data?.modules?.audiences),
        activation: getBoolValue(data?.modules?.activation),
        funnel: getBoolValue(data?.modules?.funnel),
        ermesLive: getBoolValue(data?.modules?.ermesLive)
      };
      setRows([mapped]);
    } else {
      setDomainConfigList([]);
      setRows([]);
    }
    setImportModels(
      resImportModels.map(e => ({ label: e.label, value: e.model }))
    );
    setConnectorsConnexions(
      connexions.map(e => ({ label: e.name, value: e._id, model: e.model }))
    );
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const handleCreateDomainConfig = async data => {
    try {
      const response = await DOMAINCONFIG.create({
        ...data,
        domain: user.domain
      });
      if (response) {
        setModal(null);
        setLoaded(false);
      }
    } catch (err) {
      console.error(err.message);
      throw err;
    }
  };

  const handleSetupDomain = async data => {
    try {
      const res = await DOMAINCONFIG.create(data);
      if (res) {
        setModal(null);
        setLoaded(false);
      }
    } catch (err) {
      console.error(err.message);
      throw err;
    }
  };

  const handleUpdateDomainConfig = async data => {
    try {
      const apis = [];
      if (data.autoLogin !== undefined) {
        apis.push(DOMAINCONFIG.updateAutoLogin(data.domain, data.autoLogin));
      }
      apis.push(
        DOMAINCONFIG.update(domainConfig._id, {
          ...data,
          domain: user.domain
        })
      );

      await Promise.all(apis);

      setModal(null);
      await resfreshDomainConfig();
      setLoaded(false);
    } catch (err) {
      console.error(err.message);
      throw err;
    }
  };

  const addDomainConfig = async () => {
    if (adminRights?.general?.domain?.create) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const setupDomain = async () => {
    if (adminRights?.general?.domain?.create) {
      setModal('modalSetup');
    } else {
      setModal('unauthorized');
    }
  };

  const updateDomainConfig = async () => {
    if (adminRights?.general?.domain?.update) {
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  return (
    <Container>
      <Row spacing>
        <Loading loading={!loaded}>
          <Item>
            <Row spacing={5}>
              <Item justify="flex-start" xs>
                <Row justify="space-between">
                  <Button
                    variant="contained"
                    onClick={setupDomain}
                    color={themeColors.black}
                  >
                    <Container>
                      <Row spacing={1} alignItems="center">
                        <AddIcon />
                        &nbsp;
                        <Text color="inherit">Créer un nouveau domaine</Text>
                      </Row>
                    </Container>
                  </Button>
                </Row>
              </Item>
            </Row>
            <Row spacing={5}>
              {domainConfigList.length === 0 ? (
                <Item justify="center">
                  <Button
                    variant="contained"
                    onClick={addDomainConfig}
                    color={themeColors.black}
                  >
                    <Row spacing={1} alignItems="center">
                      <SettingsIcon />
                      &nbsp;
                      <Text color="inherit">
                        Configurer le domain {user.domain}
                      </Text>
                    </Row>
                  </Button>
                </Item>
              ) : (
                <Item justify="center">
                  <TableData
                    rows={rows}
                    headers={[
                      { label: 'Logo', id: 'logo', sortKey: 'logo' },
                      { label: 'Key', id: 'domain', sortKey: 'domain' },
                      { label: 'Domaine', id: 'name', sortKey: 'name' },
                      {
                        label: 'CRM',
                        id: 'crm',
                        sortKey: 'crm'
                      },
                      {
                        label: 'Audiences',
                        id: 'audiences',
                        sortKey: 'audiences'
                      },
                      {
                        label: 'Activation',
                        id: 'activation',
                        sortKey: 'activation'
                      },
                      { label: 'Funnel', id: 'funnel', sortKey: 'funnel' },
                      {
                        label: 'ERMES Live',
                        id: 'ermesLive',
                        sortKey: 'ermesLive'
                      }
                    ]}
                    hidenFields={['_id']}
                    onUpdate={updateDomainConfig}
                  />
                </Item>
              )}
            </Row>
          </Item>
        </Loading>
      </Row>
      {openModal === 'modalUpdate' && (
        <ModalForm
          actionName="Mettre à jour"
          open={openModal === 'modalUpdate'}
          onClose={closeModal}
          onValidate={handleUpdateDomainConfig}
          config={domainConfig}
          dataProviders={dataProviders}
          preset={presetList}
          connectorsConnexions={connectorsConnexions}
          importModels={importModels}
          activableMedias={activableMedias}
        />
      )}
      {openModal === 'unauthorized' && (
        <UnauthorizedModal
          open={openModal === 'unauthorized'}
          onClose={closeModal}
        />
      )}
      {openModal === 'modalCreate' && (
        <ModalForm
          open={openModal === 'modalCreate'}
          actionName="Configurer"
          onClose={closeModal}
          onValidate={handleCreateDomainConfig}
          config={{}}
          connectorsConnexions={connectorsConnexions}
          importModels={importModels}
          activableMedias={activableMedias}
        />
      )}
      {openModal === 'modalSetup' && (
        <ModalSetup
          open={openModal === 'modalSetup'}
          actionName="Configurer"
          onClose={closeModal}
          onValidate={handleSetupDomain}
          dataProviders={dataProviders}
          presetList={presetList}
          adminRole={adminRole}
          activableMedias={activableMedias}
        />
      )}
    </Container>
  );
}

export default DomainContainer;
