/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Chip, Collapse } from '@mui/material';
import IntervalTimer from 'react-interval-timer';

import { useTheme } from 'contexts/ThemeContext';

import Icon from 'utils/icons';
import numeral from 'utils/numeral';
import { ACTIVATION, CONNEXION, IMPORTMODEL } from 'utils/rest';

import { Divider, Text, Tooltip } from 'components/DataDisplay';
import { FormSection } from 'components/FormSection';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';

import {
  ExpendedItem,
  InvoiceItem,
  MediaOrderItemMulti,
  TableConnectorMultiComponent
} from './components';

const ModalParamsMulti = props => {
  const { themeColors } = useTheme();
  const {
    config,
    campaign,
    setupPendingTooltip,
    updatedCampaigns,
    loading
  } = props;
  const [isExpandedSetupCampaign, setIsExpandedSetupCampaign] = useState(true);
  const [isExpandedSetupAccount, setIsExpandedSetupAccount] = useState(true);
  const [mediaOrders, setMediaOrders] = useState([]);
  const [mediaLogosToDisplay, setMediaLogosToDisplay] = useState([]);
  const [activableMedias, setActivableMedias] = useState([]);
  const [importModels, setImportModels] = useState([]);
  const [campaignSetupTasks, setCampaignSetupTasks] = useState([]);
  const [updatePlatforms, setUpdatePlatforms] = useState(false);
  const [organisation, setOrganisation] = useState(null);
  const [connexions, setConnexions] = useState([]);
  const [activationShopGroups, setActivationShopGroups] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [ready, setReady] = useState(true);

  const [allPlatformAccounts] = useState(
    config.groups.flatMap(group =>
      group.platformAccounts.map(account => ({
        ...account,
        groupName: group.name
      }))
    )
  );
  const [allPlatformPages] = useState(
    config.groups.flatMap(group =>
      group.platformPages.map(page => ({
        ...page,
        groupName: group.name
      }))
    )
  );
  const [formattedPlatforms, setFormattedPlatforms] = useState([]);
  const { mediaLogos = [] } = config;
  const campaignId = campaign.id || campaign._id;

  const getBudgetMediaForMediaOrder = ({ media, withDataPlan = null, id }) => {
    if (!withDataPlan) {
      return media.budget;
    }
    const isTraditionalMedia = withDataPlan.channels.find(
      el => el.key === media.activableMedia
    );

    if (isTraditionalMedia) {
      return isTraditionalMedia.budget.media;
    }
    const budgetMedia = withDataPlan.channels.reduce((acc, channel) => {
      const mediaDataFinded =
        channel?.mediaData?.find(
          md => md.media === media.activableMedia && md.enabled
        ) || null;
      if (mediaDataFinded) {
        acc += mediaDataFinded.budget.media;
      }
      return acc;
    }, 0);
    return budgetMedia;
  };

  const checkCampaignSetupTask = ({ masterCampaignSetupTask = [] }) => {
    if (masterCampaignSetupTask.length > 0) {
      return masterCampaignSetupTask[0];
    }
    return null;
  };

  const isSetupEnded = ({ masterCampaignSetupTask = [] }) => {
    if (masterCampaignSetupTask.length > 0) {
      if (
        masterCampaignSetupTask[0].status === 'done' ||
        masterCampaignSetupTask[0].status === 'error'
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  const getSetupTask = async ({
    foundCampaignSetupTasks,
    mediaOrderId,
    masterCampaignSetupTask = [],
    media
  }) => {
    const masterCampaignSetup = checkCampaignSetupTask({
      masterCampaignSetupTask
    });
    if (masterCampaignSetup && !masterCampaignSetup.mediaOrderId) {
      const res = await ACTIVATION.getMediaSetupTaskByCampaignSetupTask([
        masterCampaignSetup.id
      ]);
      return (
        res
          .filter(el => {
            if (media === 'instagram' || media === 'facebook') {
              return el.platform === 'meta';
            }
            return el.platform === media;
          })
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0] ||
        masterCampaignSetup
      );
    }
    return (
      foundCampaignSetupTasks
        .filter(
          foundCampaignSetupTask =>
            foundCampaignSetupTask.mediaOrderId === mediaOrderId
        )
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0] ||
      null
    );
  };

  const getDisplaySetup = ({
    mediaOrder,
    allActivableMedias,
    allImportModels
  }) => {
    const model = allActivableMedias.find(
      el => el.key === mediaOrder.activableMedia
    )?.model;
    const importModel = allImportModels.find(el => el.connectorName === model);
    return importModel?.canSetUp;
  };

  const geSetup = ({
    mediaOrder,
    allActivableMedias,
    allImportModels,
    allCampaignSetupTasks,
    masterCampaignSetupTask = []
  }) => {
    const canSetup = getDisplaySetup({
      mediaOrder,
      allActivableMedias,
      allImportModels
    });
    const selectedSetupTask =
      allCampaignSetupTasks
        .filter(el => el.mediaOrderId === mediaOrder._id)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0] ||
      null;
    if (canSetup) {
      const reponse = checkCampaignSetupTask({
        masterCampaignSetupTask
      });
      if (reponse) {
        if (!reponse.mediaOrderId) {
          return isSetupEnded({
            masterCampaignSetupTask
          });
        }
      }
      if (
        selectedSetupTask &&
        (selectedSetupTask.status === 'done' ||
          selectedSetupTask.status === 'error')
      ) {
        return true;
      }
      if (
        selectedSetupTask &&
        (selectedSetupTask.status !== 'done' ||
          selectedSetupTask.status !== 'error')
      ) {
        return false;
      }

      return true;
    }
    return false;
  };

  const formatPlatform = async (
    allActivableMedias,
    foundMediaOrders,
    foundImportModels
  ) => {
    const platforms = updatedCampaigns.reduce((acc, campaignConfig) => {
      acc.push(campaignConfig.platforms);
      return acc;
    }, []);

    const result = foundMediaOrders.flat().reduce((acc, mediaOrder) => {
      const media = mediaOrder.activableMedia;
      const activableMedia = allActivableMedias.find(
        e => e.key === media && e.canSetUp
      );
      if (!activableMedia) {
        return acc;
      }

      const importModel = foundImportModels.find(
        e => e.connectorName === activableMedia.model
      );
      const mediaPlatforms = platforms
        .flat()
        .filter(platform => platform.media === media);
      for (const platform of mediaPlatforms) {
        const exist = acc.find(
          el =>
            el.media === platform.media &&
            el.campaignId === mediaOrder.campaignId &&
            el.type === platform.type
        );
        if (exist) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const item = {
          _id: platform.media,
          media: platform.media,
          account: {
            media,
            model: importModel?.model
          },
          model: importModel?.model,
          page: platform.type === 'account' ? null : platform,
          drawer: false,
          campaignId: mediaOrder.campaignId,
          type: platform.type
        };
        acc.push(item);
      }
      if (mediaPlatforms.length === 0) {
        // on créé la platform si pas dans la campaign mais bien dans le mediaorder
        acc.push({
          _id: media,
          media,
          account: {
            media,
            model: importModel?.model
          },
          page: activableMedia?.page ? { media } : null,
          drawer: false,
          campaignId: mediaOrder.campaignId,
          type: activableMedia?.page ? 'page' : 'account'
        });
      }
      return acc;
    }, []);
    if (platforms.flat().length === 0) {
      setFormattedPlatforms(result);
    } else {
      const test = result.map(el => {
        const camp = updatedCampaigns.find(
          subcamp => subcamp._id === el.campaignId
        );
        if (el.type === 'account') {
          el.account.config = camp?.platforms?.find(
            platform =>
              platform.media === el.media && platform.type === 'account'
          ).config;
        } else if (el.type === 'page') {
          const found = camp?.platforms?.find(
            platform => platform.media === el.media && platform.type === 'page'
          );
          const element = {
            ...el,
            page: found
          };
          return element;
        }
        return el;
      });

      setFormattedPlatforms(test);
    }
  };

  const fetchData = async () => {
    const [shopGroups, allConnexions] = await Promise.all([
      ACTIVATION.getActivationShopGoups(),
      CONNEXION.getConnexions()
    ]);

    const foundMediaOrders = [];
    const foundLineItems = [];
    const foundAds = [];
    for await (const campaignConfigId of campaign.campaignConfigIds) {
      foundMediaOrders.push(
        await ACTIVATION.getMediaOrdersByCampaignId(campaignConfigId)
      );
      foundLineItems.push(
        await ACTIVATION.getLineItemsByCampaignId(campaignConfigId)
      );
      foundAds.push(
        await ACTIVATION.getCreativesByCampaignId(campaignConfigId)
      );
    }

    const [
      masterCampaignSetupTask,
      foundCampaignSetupTasks,
      foundActivableMedias,
      foundImportModels
    ] = await Promise.all([
      ACTIVATION.getCampaignLastSetupTasks(campaign.campaignConfigIds),
      ACTIVATION.getCampaignSetupTaskByCampaignId({
        ids: campaign.campaignConfigIds
      }),
      ACTIVATION.getActivableMedias(),
      IMPORTMODEL.get()
    ]);
    formatPlatform(foundActivableMedias, foundMediaOrders, foundImportModels);
    const group = config.groups.find(el => el._id === campaign.groupId);
    setOrganisation(group?.name || 'Custom BO');
    setConnexions(allConnexions);
    setActivationShopGroups(shopGroups);

    setCampaignSetupTasks(masterCampaignSetupTask);
    setActivableMedias(foundActivableMedias);

    setImportModels(foundImportModels);
    const foundMediaOrdersState = [];
    for await (const mediaOrder of foundMediaOrders.flat()) {
      const setupTask = await getSetupTask({
        foundCampaignSetupTasks,
        mediaOrderId: mediaOrder._id,
        masterCampaignSetupTask: foundCampaignSetupTasks
          .filter(el => el.campaignConfigsId === mediaOrder.campaignId)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
        media: mediaOrder.activableMedia
      });
      foundMediaOrdersState.push({
        _id: mediaOrder._id,
        activableMedia: mediaOrder.activableMedia,
        budget: mediaOrder.budget,
        campaignId: mediaOrder.campaignId,
        contacts: mediaOrder.contacts,
        budgetMedia: getBudgetMediaForMediaOrder({
          media: mediaOrder,
          withDataPlan: updatedCampaigns.find(
            el => el._id === mediaOrder.campaignId
          ).withDataPlan,
          id: mediaOrder.campaignId
        }),
        externalId: mediaOrder.externalId?.externalId,
        isExpanded: true,
        isEditing: false,
        displaySetup: getDisplaySetup({
          mediaOrder,
          allActivableMedias: foundActivableMedias,
          allImportModels: foundImportModels
        }),
        setupTask,
        setup: geSetup({
          mediaOrder,
          allActivableMedias: foundActivableMedias,
          allImportModels: foundImportModels,
          allCampaignSetupTasks: foundCampaignSetupTasks,
          masterCampaignSetupTask
        })
      });
    }
    setMediaOrders(foundMediaOrdersState);
    setLoaded(true);
  };

  const toggleExpandedCampaignSetup = key => {
    if (key === 'all') {
      setIsExpandedSetupCampaign(!isExpandedSetupCampaign);
      return;
    }
    const newOrders = [...mediaOrders];
    const foundOrder = newOrders.find(el => el.id === key);
    foundOrder.isExpanded = !foundOrder.isExpanded;
    setMediaOrders(newOrders);
  };

  const downloadAssets = async () => {
    const id = campaign.id || campaign._id;

    try {
      const allAssets = await ACTIVATION.downloadAssetsByCampaignId(id);

      if (allAssets) {
        const blob = new Blob([allAssets], {
          type: 'application/zip'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'assets.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error('Failed to retrieve the assets.');
      }
    } catch (error) {
      console.error('Error downloading assets:', error);
    }
  };

  const getStatusChip = (activableMedia, mediaSetupTask = {}) => {
    if (activableMedia.canSetUp !== true) {
      return (
        <Chip
          label="Setup manuel"
          icon={<ReportProblemIcon />}
          style={{
            backgroundColor: themeColors.badgeDomains,
            borderRadius: '3px',
            fontWeight: 600,
            fontSize: '12px',
            marginLeft: '18px',
            marginRight: '18px'
          }}
        />
      );
    }
    switch (mediaSetupTask?.status) {
      case 'toDo':
        return (
          <Chip
            label="Synchronisable"
            icon={<ErrorOutlineIcon />}
            style={{
              backgroundColor: themeColors.badgeFunnel,
              borderRadius: '3px',
              fontWeight: 600,
              fontSize: '12px',
              marginLeft: '18px',
              marginRight: '18px'
            }}
          />
        );
      case 'done':
        return (
          <Chip
            label="Synchronisé"
            icon={<CheckIcon />}
            style={{
              backgroundColor: themeColors.badgeActif,
              borderRadius: '3px',
              fontWeight: 600,
              fontSize: '12px',
              marginLeft: '18px',
              marginRight: '18px'
            }}
          />
        );

      case 'error':
        return (
          <Tooltip title={mediaSetupTask.errorMsg}>
            <Chip
              label="Erreur"
              icon={<CancelOutlinedIcon />}
              style={{
                backgroundColor: themeColors.error_gestion_campagne,
                borderRadius: '3px',
                fontWeight: 600,
                fontSize: '12px',
                marginLeft: '18px',
                marginRight: '18px'
              }}
            />
          </Tooltip>
        );
      case 'partial':
        return (
          <Chip
            label="Setup manuel"
            icon={<CheckIcon />}
            style={{
              backgroundColor: themeColors.badgeActif,
              borderRadius: '3px',
              fontWeight: 600,
              fontSize: '12px',
              marginLeft: '18px',
              marginRight: '18px'
            }}
          />
        );
      // TODO : Remove status
      // Actuellement on reçoit des campaignSetupTask et pas des mediaSetupTask, les status sont diff.
      case 'uploadingSegments':
      case 'uploadInProgress':
      case 'segmentsExported':
      case 'uploadingCreatives':
      case 'creativesUploaded':
      case 'SettingUpCampaign':
        return (
          <Chip
            label="En cours"
            style={{
              backgroundColor: themeColors.grey_light_button,
              borderRadius: '3px',
              fontWeight: 600,
              fontSize: '12px',
              marginLeft: '18px',
              marginRight: '18px'
            }}
          />
        );
      case 'creatingOrder':
      case 'orderCreated':
      case 'creatingLineItems':
      case 'lineItemsCreated':
      case 'attachingAds':
      default:
        switch (campaignSetupTasks[0]?.status) {
          case 'toDo':
          case 'uploadingSegments':
          case 'segmentsExported':
          case 'uploadingCreatives':
          case 'creativesUploaded':
          case 'SettingUpCampaign':
            return (
              <Chip
                label="En cours"
                style={{
                  backgroundColor: themeColors.grey_light_button,
                  borderRadius: '3px',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginLeft: '18px',
                  marginRight: '18px'
                }}
              />
            );
          case 'done':
            return (
              <Chip
                label="Synchronisé"
                icon={<CheckIcon />}
                style={{
                  backgroundColor: themeColors.badgeActif,
                  borderRadius: '3px',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginLeft: '18px',
                  marginRight: '18px'
                }}
              />
            );
          case 'error':
            return (
              <Chip
                label="Erreur"
                icon={<CancelOutlinedIcon />}
                style={{
                  backgroundColor: themeColors.error_gestion_campagne,
                  borderRadius: '3px',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginLeft: '18px',
                  marginRight: '18px'
                }}
              />
            );
          default:
            return (
              <Chip
                label="Synchronisable"
                icon={<ErrorOutlineIcon />}
                style={{
                  backgroundColor: themeColors.badgeFunnel,
                  borderRadius: '3px',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginLeft: '18px',
                  marginRight: '18px'
                }}
              />
            );
        }
    }
  };

  const getCampaignSetupTasks = async () => {
    setReady(false);
    const masterCampaignTask = await ACTIVATION.getCampaignLastSetupTasks([
      campaign.campaignConfigIds
    ]);
    if (masterCampaignTask) {
      setCampaignSetupTasks(masterCampaignTask);
    }

    const allCampaignSetupTasks = [];
    for await (const ids of campaign.campaignConfigIds) {
      const res = await ACTIVATION.getCampaignSetupTaskByCampaignId({
        ids: [ids]
      });
      allCampaignSetupTasks.push(res);
    }

    const newmediaOrders = [];
    for await (const el of mediaOrders) {
      const setupTask = await getSetupTask({
        foundCampaignSetupTasks: allCampaignSetupTasks.flat(),
        mediaOrderId: el._id,
        masterCampaignSetupTask: allCampaignSetupTasks
          .flat()
          .filter(curr => curr.campaignConfigsId === el.campaignId)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
        media: el.activableMedia
      });
      newmediaOrders.push({
        ...el,
        id: el._id,
        setupTask,
        setup: geSetup({
          mediaOrder: el,
          allActivableMedias: activableMedias,
          allImportModels: importModels,
          allCampaignSetupTasks,
          masterCampaignSetupTask: allCampaignSetupTasks
            .flat()
            .filter(curr => curr.campaignConfigsId === el.campaignId)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        })
      });
    }
    setMediaOrders(newmediaOrders);
    setReady(true);
  };

  const launchSetupCampaign = async () => {
    for await (const configId of campaign.campaignConfigIds) {
      await ACTIVATION.launchCampaignSetup(configId);
    }
    setMediaOrders(
      mediaOrders.map(el => {
        return {
          ...el,
          setup: false
        };
      })
    );
    getCampaignSetupTasks();
  };

  const launchSetup = async mediaOrder => {
    await ACTIVATION.postCampaignSetup({
      campaignConfigsId: mediaOrder.campaignId,
      mediaOrder
    });
    setMediaOrders(
      mediaOrders.map(el => {
        if (el.activableMedia === mediaOrder.activableMedia) {
          return { ...el, setup: false };
        }
        return el;
      })
    );
    getCampaignSetupTasks();
  };

  const handleSaveMediaOrder = async (id, newExternalId) => {
    const order = await ACTIVATION.getMediaOrderById(id);
    if (order.externalId) {
      order.externalId.externalId = newExternalId;
    } else {
      order.externalId = {
        externalId: newExternalId
      };
    }
    await ACTIVATION.updateMediaOrderById(id, order);
  };

  useEffect(() => {
    const logosToDisplay = mediaOrders.map(mediaOrder => {
      const key =
        mediaOrder.activableMedia === 'search'
          ? 'googleads'
          : mediaOrder.activableMedia.toLowerCase();
      const matchingLogo = mediaLogos.find(
        logo => logo.key.toLowerCase() === key
      );
      return matchingLogo ? matchingLogo.base64Image : null;
    });

    setMediaLogosToDisplay(logosToDisplay);
    // eslint-disable-next-line
  }, [mediaOrders, mediaLogos]);

  const savePlatform = async newPlatforms => {
    const configs = newPlatforms.reduce((acc, el) => {
      const exist = acc.find(curr => curr._id === el.campaignId);
      if (exist) {
        exist.platforms.push({
          media: el.media,
          type: el.type,
          config: el[el.type].config ? el[el.type].config : 'undefined'
        });
      } else {
        const platform = {
          media: el.media,
          type: el.type,
          config: el[el.type].config ? el[el.type].config : 'undefined'
        };
        acc.push({
          _id: el.campaignId,
          platforms: [platform]
        });
      }
      return acc;
    }, []);
    await ACTIVATION.updateManyCampaignConfigs(configs);
  };

  const displayDrawerInput = key => {
    const getCertified = data => {
      if (!data) {
        return null;
      }
      if (data.media === 'sms') {
        if (data?.config?.connexionId === '') {
          return {
            ...data,
            config: 'noExist'
          };
        }
      } else if (data?.config?.config?.externalId === '') {
        return {
          ...data,
          config: 'noExist'
        };
      }

      return data;
    };

    const newPlatforms = formattedPlatforms.map(el => {
      if (el._id === key) {
        return {
          ...el,
          account: getCertified(el.account),
          page: getCertified(el.page),
          drawer: !el.drawer
        };
      }
      return { ...el };
    });
    setFormattedPlatforms(newPlatforms);

    if (!newPlatforms.some(el => el.drawer) && updatePlatforms) {
      savePlatform(newPlatforms);
    }
  };

  const isObject = data => {
    return data !== null && typeof data === 'object';
  };

  const getMediaModelByMedia = media => {
    const activableMediaFounded = activableMedias.find(
      activableMedia => activableMedia.key === media
    );

    const importModelFounded = importModels.find(
      importModel => importModel.connectorName === activableMediaFounded.model
    );

    return importModelFounded.model;
  };

  const getBudgetMedia = media => {
    const budget = mediaOrders.reduce((acc, el) => {
      if (el.activableMedia === media) {
        acc += el.budgetMedia;
      }
      return acc;
    }, 0);
    return budget;
  };

  const handleChangeExternalId = (type, media) => value => {
    let tmp = [];

    const activableMediaModel =
      activableMedias.find(el => el.key === media)?.model || null;
    const connexionId =
      connexions.find(el => el.model === activableMediaModel)?._id || null;
    if (media === 'sms') {
      tmp = formattedPlatforms.map(formattedPlatform => {
        if (formattedPlatform.media === media) {
          if (isObject(formattedPlatform[type].config)) {
            formattedPlatform[type].config = {
              connexionId: value?.key,
              name: value?.label
            };
          } else {
            formattedPlatform[type] = {
              media,
              type,
              config: {
                connexionId: value?.key,
                name: value?.label
              }
            };
          }
        }
        return formattedPlatform;
      });
    } else {
      tmp = formattedPlatforms.map(formattedPlatform => {
        if (formattedPlatform.media === media) {
          if (isObject(formattedPlatform[type]?.config)) {
            if (isObject(formattedPlatform[type]?.config?.config)) {
              formattedPlatform[type].config = {
                model: formattedPlatform[type].model,
                media: formattedPlatform[type].media,
                name: value?.group ? value.group : organisation,
                status: 'validated',
                config: {
                  externalId: value?.key,
                  connexionId
                }
              };
            } else {
              formattedPlatform[type].config = {
                config: {
                  externalId: value?.key,
                  connexionId
                }
              };
            }
          } else {
            formattedPlatform[type] = {
              media,
              type,
              config: {
                model: getMediaModelByMedia(media),
                name: value?.group ? value.group : organisation,
                status: 'validated',
                config: {
                  externalId: value?.key,
                  connexionId
                }
              }
            };
          }
        }
        return formattedPlatform;
      });
    }
    if (value?.group) {
      setOrganisation(value.group);
    }
    setFormattedPlatforms(tmp);
  };

  useEffect(() => {
    const isUpdate = formattedPlatforms.some(el => el.drawer);
    if (isUpdate) {
      setUpdatePlatforms(isUpdate);
    }
  }, [formattedPlatforms]);

  useEffect(() => {
    // formatPlatform();
    if (!loading) fetchData();
    // eslint-disable-next-line
  }, [loading]);

  const isCampaignSetupPending =
    campaignSetupTasks.length > 0 &&
    !['done', 'error'].includes(campaignSetupTasks[0].status);
  const launchButtonColor = isCampaignSetupPending
    ? themeColors.themeV2.detail
    : themeColors.activation;

  const handleCampaignOptions = (camp, opt) => {
    if (!opt) {
      return;
    }

    const typeOfPrice = {
      media: 'percentMedia',
      fix: 'fix',
      total: 'percentTotal'
    };

    const calculatePrice = (typeOfBudget, percentage) => {
      const budget = Math.round(
        typeOfBudget === typeOfPrice.total
          ? camp.budget
          : camp.withDataPlan.budget.media
      );

      return Math.round(budget * (percentage / 100));
    };

    let optionPrice = 0;

    const { type, amount } = opt.price;

    switch (type) {
      case typeOfPrice.fix:
        optionPrice = amount;
        break;
      default:
        optionPrice = calculatePrice(type, amount);
        break;
    }
    // eslint-disable-next-line consistent-return
    return opt.amount ? opt.amount : optionPrice;
  };

  function sumAndRoundBudget() {
    const budgetOptions = updatedCampaigns.reduce(
      (acc, el) =>
        acc +
        (el.budgetOptions || 0) +
        (el?.withDataPlan?.budget?.media || 0) +
        (el?.withDataPlan?.budget?.technicalFees || 0) +
        (el?.withDataPlan?.budget?.data || 0) +
        (el?.withDataPlan?.budget?.functionalFees || 0),
      0
    );
    return Math.round(budgetOptions);
  }

  const datas = campaign?.withDataPlan.length
    ? campaign?.withDataPlan
    : campaign?.campaignConfigs;

  const {
    mediaBudget,
    dataBudget,
    functionalFees,
    technicalFees,
    margin
  } = datas?.reduce(
    (acc, el) => {
      acc.mediaBudget += el.withDataPlan?.budget?.media;
      acc.dataBudget += el.withDataPlan?.budget?.data;
      acc.functionalFees += el.withDataPlan?.budget?.functionalFees;
      acc.technicalFees += el.withDataPlan?.budget?.technicalFees;
      acc.margin += el.withDataPlan?.budget?.margin;

      return acc;
    },
    {
      mediaBudget: 0,
      dataBudget: 0,
      functionalFees: 0,
      technicalFees: 0,
      margin: 0
    }
  );

  const invoiceItemConfigs = [
    {
      label: "Achat d'espace",
      amount: mediaBudget
    },
    {
      label: 'Data',
      amount: dataBudget
    },
    {
      label: 'Frais de routage',
      amount: functionalFees
    },
    {
      label: 'Frais techniques',
      amount: technicalFees
    },
    {
      label: 'Marge',
      amount: margin,
      showItem: !!margin
    }
  ];

  const formatOptions = () => {
    const paddingLeft = campaign.options?.length > 1 ? '40px' : null;
    const listCampaignOptions =
      campaign.options?.map(option => (
        <InvoiceItem
          paddingLeft={paddingLeft}
          label={option.name}
          amount={numeral(handleCampaignOptions(campaign, option)).format(
            '0,0 $'
          )}
        />
      )) || [];
    return (
      <>
        {listCampaignOptions.length > 1 && (
          <Item justify="flex-start">
            <Item flex>
              <Text>Options</Text>
            </Item>
            <Item
              xs
              justify="flex-start"
              style={{
                paddingRight: '5px',
                paddingLeft: '5px'
              }}
            >
              <Divider
                color="light"
                noFade
                style={{
                  width: '100%',
                  height: '0.5px'
                }}
              />
            </Item>
            <Item flex>
              <Text fontWeight={700}>{`${numeral(
                campaign?.budgetOptions
              ).format('0,0 $')}`}</Text>
            </Item>
          </Item>
        )}
        {listCampaignOptions}
      </>
    );
  };

  return (
    <Container>
      <IntervalTimer
        timeout={10000}
        callback={ready && getCampaignSetupTasks}
        enabled
        repeat
      />
      <Row spacing={1} style={{ paddingBottom: '30px' }}>
        <Item justify="flex-start">
          <Text fontWeight={700} fontSize="20px">
            {campaign.name}&nbsp;
            <Text component="span" fontSize="20px" fontWeight={400}>
              ({campaignId})
            </Text>
          </Text>
        </Item>
      </Row>
      {campaign.withDataPlan && (
        <Row spacing={1}>
          <Item>
            <FormSection title="Récapitulatif" xs={3}>
              <Container>
                <Row spacing={1}>
                  <Item xs={11} alignItems="flex-start">
                    <Item xs={2}>
                      <Text fontWeight={700} fontSize="14px">
                        Facturation
                      </Text>
                    </Item>
                    <Item
                      xs={10}
                      style={{
                        backgroundColor: themeColors.themeV2.btnGreyBackground,
                        paddingBottom: '24px',
                        paddingRight: '24px'
                      }}
                    >
                      <Row>
                        {invoiceItemConfigs.map(item => (
                          <InvoiceItem
                            key={item.label}
                            label={item.label}
                            amount={item.amount}
                            showItem={item.showItem}
                          />
                        ))}

                        {campaign.options.length > 0 && formatOptions()}

                        <InvoiceItem
                          label="Total HT"
                          isBoldLabel
                          amount={sumAndRoundBudget()}
                        />
                      </Row>
                    </Item>

                    {campaign.orderStatus === 'paid' && (
                      <Text fontWeight={700} fontSize="14px">
                        Payeur :{' '}
                        {campaign.paidByShops ? 'Mes Magasins' : campaign.name}
                      </Text>
                    )}
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      )}
      <Row spacing={1}>
        <ExpendedItem
          toggleExpanded={() => {
            toggleExpandedCampaignSetup('all');
          }}
          isExpanded={isExpandedSetupCampaign}
          title="Setup campaign"
        />
      </Row>
      <Loading loading={!loaded} />
      <Row spacing={0}>
        <Item justify="flex-start">
          <Collapse
            in={isExpandedSetupCampaign}
            timeout="auto"
            unmountOnExit
            style={{ width: '100%' }}
          >
            {mediaOrders.length > 0 &&
              mediaOrders
                .reduce((acc, mediaOrder) => {
                  const exist = acc.find(
                    el => el.activableMedia === mediaOrder.activableMedia
                  );
                  if (!exist) {
                    const item = {
                      ...mediaOrder,
                      budgetMedia: getBudgetMedia(mediaOrder.activableMedia)
                    };
                    acc.push(item);
                  } else {
                    if (
                      exist.setupTask?.status !== 'error' &&
                      mediaOrder?.setupTask?.status !== 'done' &&
                      mediaOrder.setupTask?.status !== 'error'
                    ) {
                      exist.setupTask = mediaOrder.setupTask;
                    }

                    if (mediaOrder?.setupTask?.status === 'error') {
                      exist.setupTask = mediaOrder.setupTask;
                    }
                  }
                  return acc;
                }, [])
                .map((mediaOrder, mediaOrderIndex) => {
                  const activableMedia = activableMedias.find(
                    el => el.key === mediaOrder.activableMedia
                  );
                  return (
                    <MediaOrderItemMulti
                      mediaOrder={mediaOrder}
                      toggleExpanded={toggleExpandedCampaignSetup}
                      mediaLogoDisplay={mediaLogosToDisplay[mediaOrderIndex]}
                      activableMedia={activableMedia}
                      getStatusChip={getStatusChip}
                      setMediaOrders={setMediaOrders}
                      handleSaveMediaOrder={handleSaveMediaOrder}
                      mediaSetupTask={mediaOrder.setupTask}
                      launchSetup={launchSetup}
                    />
                  );
                })}
          </Collapse>
        </Item>
      </Row>
      <Row
        spacing={1}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '50px'
        }}
      >
        <Item flex>
          <Button
            variant="contained"
            size="medium"
            color={themeColors.black}
            onClick={() => {
              downloadAssets();
            }}
          >
            <DownloadIcon />
            &nbsp;
            <Text fontSize="12px" fontWeight={600} color="inherit">
              Télécharger tous les assets
            </Text>
          </Button>
        </Item>
        <Item flex>
          {' '}
          <Tooltip
            title={setupPendingTooltip}
            disabled={!isCampaignSetupPending}
          >
            <Button
              variant="contained"
              size="medium"
              color={launchButtonColor}
              fontColor={isCampaignSetupPending && themeColors.keep_dark}
              onClick={() => {
                launchSetupCampaign();
              }}
              disabled={isCampaignSetupPending}
            >
              <Icon
                icon="setUpAutoIcon"
                attrs={{
                  circleColor: themeColors.keep_light,
                  arrowColor: isCampaignSetupPending
                    ? themeColors.themeV2.subtitle
                    : themeColors.activation
                }}
              />
              &nbsp;
              <Text fontSize="12px" fontWeight={600} color="inherit">
                Lancer le setup sur tous les médias compatibles
              </Text>
            </Button>
          </Tooltip>
        </Item>
      </Row>
      {formattedPlatforms.length > 0 ? (
        <>
          <Row spacing={1} style={{ marginTop: '20px' }}>
            <ExpendedItem
              title="Configuration des comptes"
              toggleExpanded={() => {
                setIsExpandedSetupAccount(!isExpandedSetupAccount);
              }}
              isExpanded={isExpandedSetupAccount}
            />
          </Row>
          <Row spacing={1}>
            <Item justify="flex-start">
              <Collapse
                in={isExpandedSetupAccount}
                timeout="auto"
                unmountOnExit
                style={{ width: '100%' }}
              >
                {formattedPlatforms
                  .reduce((acc, el) => {
                    const exist = acc.find(item => item.media === el.media);
                    if (!exist) {
                      const item = {
                        ...el,
                        campaignId: new Set([el.campaignId])
                      };
                      acc.push(item);
                    } else {
                      exist.campaignId.add(el.campaignId);
                      if (el.type === 'account') {
                        exist.account = el.account;
                      }
                      if (el.type === 'page') {
                        exist.page = el.page;
                      }
                    }
                    return acc;
                  }, [])
                  .map(formattedPlatform => (
                    <>
                      <TableConnectorMultiComponent
                        setupPendingTooltip={setupPendingTooltip}
                        isCampaignSetupPending={isCampaignSetupPending}
                        mediaLogos={mediaLogos}
                        allPlatformAccounts={allPlatformAccounts}
                        allPlatformPages={allPlatformPages}
                        formattedPlatforms={[formattedPlatform]}
                        displayInput={displayDrawerInput}
                        handleChangeExternalId={handleChangeExternalId}
                        campaign={campaign}
                        activationShopGroups={activationShopGroups}
                      />
                    </>
                  ))}
              </Collapse>
            </Item>
          </Row>
        </>
      ) : (
        <Loading loading={!loaded} />
      )}
    </Container>
  );
};

export default ModalParamsMulti;
